document.addEventListener("DOMContentLoaded", () => {
  const sections = document.querySelectorAll(".notices-brand-section");
  const categoryElements = document.querySelectorAll(".random-tag");
  const colors = ["#F2761E", "#39b54a", "#DF252D", "#26377f", "#18C7F1"];
  const randomColor = () => colors[Math.floor(Math.random() * colors.length)];

  // Asigna colores aleatorios a las secciones y categorías
  sections.forEach(section => section.style.backgroundColor = randomColor());
  categoryElements.forEach(category => category.style.backgroundColor = randomColor());

  /* Start - Newsletter Subscribe */
  const subscribeForm = document.getElementById("subscribe-form");
  subscribeForm?.addEventListener("submit", async (event) => {
    event.preventDefault();

    const formData = new FormData(subscribeForm);
    const data = {
      name: formData.get("name"),
      email: formData.get("email")
    };

    const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;

    try {
      const response = await fetch(subscribeForm.action, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Network response was not ok");
      }

      // Success message
      subscribeForm.parentElement.querySelector("p").textContent = result.msg;
      subscribeForm.remove();
    } catch (error) {
      console.error("Error:", error);
      subscribeForm.parentElement.querySelector("p").textContent = "Ocurrió un error, por favor intentalo de nuevo.";
    }
  });
  /* End - Newsletter Subscribe */


  /* Start - Ligthbox Last Cover */
  const mainParent = document.querySelector('.container__last-cover')
  if (mainParent) {
    const mainImage = document.getElementById('mainImage');
    const editionSelect = document.getElementById('lastSelect');
    const lightbox = document.getElementById('lightbox');
    const lightboxImage = document.getElementById('lightboxImage');
    const closeLightbox = document.getElementById('closeLightbox');
    const openLightboxBtn = document.getElementById('openLightboxBtn');

    if (mainImage && editionSelect && lightbox && lightboxImage && closeLightbox && openLightboxBtn) {

      openLightboxBtn.addEventListener('click', function () {
        lightboxImage.src = mainImage.src;  // Usar la imagen principal
        lightbox.style.display = 'flex';
      });

      closeLightbox.addEventListener('click', function () {
        lightbox.style.display = 'none';
      });

      editionSelect.addEventListener('change', function () {
        mainImage.src = editionSelect.value;
      });

      lightbox.addEventListener('click', function (event) {
        if (event.target === lightbox) {
          lightbox.style.display = 'none';
        }
      });

    }
  }
  /* End - Ligthbox Last Cover */
});
